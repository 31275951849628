import React, { PureComponent } from 'react'
import _ from 'lodash'
import Spacer from '../spacer'
import { Div, A } from 'glamorous'
import { videoList } from './video-constants'
import Video from './video'
import { smallerThan, largerThan } from '../../shared/media-queries'

import ArrowLeft from '../../imgs/icons/arrow-left.svg'

const BackLink = () => {
	return (
		<A alignItems="center" display="flex" fontSize="14px" href="/videos/">
			<ArrowLeft />
			<Spacer width="3px" />
			Back to all videos
		</A>
	)
}

class SingleVideo extends PureComponent {
	render() {
		const { videoId } = this.props
		const video = _.find(videoList, { friendlyName: videoId })

		return (
			<Div margin="0 auto" maxWidth="1280" padding="0 20px">
				<Div
					css={{
						[smallerThan('x-small')]: {
							height: '20px',
						},
						[largerThan('x-small')]: {
							height: '70px',
						},
					}}
				/>
				<Video video={video} popover={false} tracking={video.tracking}>
					{video.blurb}
					<Spacer height="10px" />
					<BackLink />
				</Video>
			</Div>
		)
	}

	componentDidMount() {
		if (window && window.scrollTo) {
			_.defer(() => window.scrollTo(0, 0))
		}
	}
}

export default SingleVideo
