import * as React from 'react'
import { Div, H3, A } from 'glamorous'

import Spacer from '../spacer'
import WistiaVideo from './wistia-video'
import { smallerThan, largerThan } from '../../shared/media-queries'
import { gutterWidth } from '../../shared/sizes'
import { navy } from '../../shared/colors'

const Video = ({ video, linkTitle, small, children, popover }) => {
	return (
		<Div
			marginBottom="80px"
			css={
				small && {
					[smallerThan('xx-small')]: {
						width: '100%',
					},
					[largerThan('xx-small')]: {
						width: `calc(50% - ${gutterWidth / 2}px)`,
					},
				}
			}
		>
			<WistiaVideo
				videoId={video.id}
				popover={popover}
				tracking={video.tracking}
			/>
			<Spacer height={30} />
			{video.friendlyName && linkTitle ? (
				<A
					href={`/video/?v=${video.friendlyName}`}
					color={navy}
					fontSize="36px"
					fontWeight={600}
					css={{
						'&:hover': {
							textDecoration: 'underline',
						},
					}}
				>
					{video.title}
				</A>
			) : (
				<H3 fontSize="36px" fontWeight={600} margin={0}>
					{video.title}
				</H3>
			)}
			<Spacer height={10} />
			<Div>{children}</Div>
		</Div>
	)
}

export default Video
