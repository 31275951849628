/* eslint-disable max-len */

import React from 'react'
import { Div } from 'glamorous'

import { watchedVideoPercentage } from '../../shared/wistia-methods'
import { trackMarketingEvent } from '../../shared/track-marketing-event'

const WistiaVideo = ({
	videoId,
	background,
	videoHeight,
	tracking,
	popover = true,
}) => (
	<Div className="wistia-wrap" background={background}>
		<div
			className="wistia_responsive_padding"
			style={{
				padding: '56.25% 0 0 0',
				position: 'relative',
			}}
			onClick={() => {
				tracking &&
					watchedVideoPercentage({
						videoId,
						percentage: tracking.percentage,
						callback: () =>
							trackMarketingEvent({
								area: tracking.area,
								object: tracking.object,
								action: tracking.action,
								name: tracking.name,
							}),
					})
			}}
		>
			<div
				className="wistia_responsive_wrapper"
				style={{
					height: '100%',
					left: 0,
					position: 'absolute',
					top: 0,
					width: '100%',
				}}
			>
				<div
					className={`wistia_embed wistia_async_${videoId} popover=${popover} popoverAnimateThumbnail=true videoFoam=true`}
					style={{
						display: 'inline-block',
						height: videoHeight || '100%',
						width: '100%',
					}}
				>
					&nbsp;
				</div>
			</div>
		</div>
	</Div>
)

export default WistiaVideo
