// @flow
import React from 'react'
import _ from 'lodash'
import qs from 'query-string'

import Layout from '../components/layout'
import SingleVideo from '../components/video/single-video'
import { videoList } from '../components/video/video-constants'
import isBrowser from 'shared/is-browser'

const VideoPage = (props: { location: { search: string } }) => {
	const videoId = qs.parse(props.location.search).v
	const videoExists = _.find(videoList, { friendlyName: videoId })

	if (!videoExists) {
		if (isBrowser) {
			window.location.href = '/videos'
		}
		return null
	}

	return (
		<Layout
			title="Videos | Watch how BuildingConnected works"
			description={`
					Learn about all the preconstruction tools that BuildingConnected has to
					offer.
				`}
		>
			<SingleVideo videoId={videoId} />
		</Layout>
	)
}

export default VideoPage
