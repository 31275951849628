export const watchedVideoPercentage = ({
	videoId,
	percentage,
	callback,
	thumbnail,
}) => {
	let { _wq } = window
	_wq = _wq || []
	_wq.push({
		id: videoId,
		onReady: video => {
			if (thumbnail) {
				video.play()
			}
			video.unbind('percentwatchedchanged')
			let mqlSent = false
			video.bind('percentwatchedchanged', percent => {
				if (Math.floor(percent * 100) > percentage && !mqlSent) {
					mqlSent = true
					callback()
				}
			})
		},
	})
}
