/* eslint-disable max-len */

export const videoList = [
	{
		friendlyName: 'bid-board-online',
		id: 'iwfqriif0n',
		title: 'Move your bid board online',
		blurb: `Save time, eliminate errors and track all your bids in one secure place.`,
	},
	{
		friendlyName: 'calendar',
		id: '43v0xa8zwd',
		title: 'Meet the smartest calendar in preconstruction',
		blurb: `Hit due dates, manage workloads and keep your team in sync with one calendar.`,
	},
	{
		friendlyName: 'bid-leveling',
		id: 'li89lenir7',
		title: 'Level Bids Faster',
		blurb: `Learn how top GCs use Bid Leveling to get out of Excel and increase efficiency, accuracy, and collaboration.`,
	},
	{
		friendlyName: 'bid-board',
		id: 'jn6jbbqhfb',
		title: 'Bid Board Pro',
		blurb:
			'Keep track of your bids, stay ahead of deadlines and measure your success with Bid Board Pro.',
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'OM Pro Video',
			action: 'Watched 75%',
			name: 'WATCHED_OM_PRO_VIDEO_75',
		},
	},
	{
		friendlyName: 'ditch-excel',
		id: 'bcnf4y5zug',
		title: 'Ditch Excel',
		blurb: 'See why Director Door ditched Excel for BuildingConnected',
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'OM Pro Video',
			action: 'Watched 75%',
			name: 'WATCHED_USER_TESTIMONIAL_75',
		},
	},
	{
		friendlyName: 'import-invites',
		id: 'uqyjvlbqxy',
		title: 'Bid Importing',
		blurb:
			'Never manually enter another bid invite. Automatically import your bids to save time and avoid errors.',
		doNotShow: true,
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'Forwarded Invites Video',
			action: 'Watched 75%',
			name: 'WATCHED_FORWARDED_INVITES_VIDEO_75',
		},
	},
	{
		friendlyName: 'testimonial-innovative-inc',
		id: 'qrxlgzbgm9',
		title: 'Innovative Mechanical Inc',
		blurb:
			'Innovative Mechanical uses BuildingConnected to hit all their bid due dates.',
		doNotShow: true,
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'Testimonial Video',
			action: 'Watched 75%',
			name: 'WATCHED_USER_TESTIMONIAL_75',
		},
	},
	{
		friendlyName: 'testimonial-complete-millwork',
		id: 'snufv3f3jo',
		title: 'Complete Millwork',
		blurb:
			'Complete Millwork moved off excel to improve their bidding process.',
		doNotShow: true,
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'Testimonial Video',
			action: 'Watched 75%',
			name: 'WATCHED_USER_TESTIMONIAL_75',
		},
	},
	{
		friendlyName: 'testimonial-bradley-concrete',
		id: 'm66a48ycjx',
		title: 'Bradley Concrete',
		blurb: 'Bradley Concrete uses email forwarding to save employee time.',
		doNotShow: true,
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'Testimonial Video',
			action: 'Watched 75%',
			name: 'WATCHED_USER_TESTIMONIAL_75',
		},
	},
	{
		friendlyName: 'testimonial-canterbury-contractors',
		id: 'v4w9iv98c9',
		title: 'Canterbury Contractors',
		blurb: 'Canterbury Contractors cut their meeting time with Bid Board Pro.',
		doNotShow: true,
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'Testimonial Video',
			action: 'Watched 75%',
			name: 'WATCHED_USER_TESTIMONIAL_75',
		},
	},
	{
		friendlyName: 'bid-forwarding',
		id: 'hl0l593rw3',
		title: 'Bid Forwarding',
		blurb:
			'Learn how subcontractors are using Bid Forwarding to simplify their bidding process.',
		tracking: {
			percentage: 75,
			area: 'Marketing Site',
			object: 'Testimonial Video',
			action: 'Watched 75%',
			name: 'WATCHED_USER_TESTIMONIAL_75',
		},
	},
]
