import axios from 'axios'
import { sitePath } from './constants'

export const trackMarketingEvent = ({ area, object, action, name }) => {
	axios.post(
		`${sitePath}/_/track-marketing-event`,
		{
			area,
			object,
			action,
			name,
		},
		{
			withCredentials: true,
		}
	)
}
